import React, { useEffect, useState, useContext } from 'react';
import SponsorsCarousel from './SponsorsCarousel';
import AboutHome from './AboutHome';
import { Image } from 'primereact/image';
import { useTranslation } from 'react-i18next';

import { DataContext } from './DataContext';
import img1 from './my_images/img1.JPG';
import img2 from './my_images/imgright.jpg';
import './css/Home.css'
import HomeTournaments from './HomeTournaments';
import HomeMedia from './HomeMedia';


function Home({ setActiveTab }) {
    const { t } = useTranslation();

    const [scrollPosition, setScrollPosition] = useState(0);
    const [isMobile, setIsMobile] = useState(false);

    const [strategic, setStrategic] = useState(null)
    const { strategicPartner } = useContext(DataContext)
    useEffect(() => {
        setStrategic(strategicPartner)
    }, [strategicPartner]);
    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.pageYOffset);
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed

            // Recalculate the scroll position on resize to handle responsive changes
            setScrollPosition(window.pageYOffset);
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        // Initial check for mobile device on component mount
        setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        setActiveTab("Home");
    }, [])

    const calculateImageOffset = (offset) => {
        const maxOffset = 200; // Define the maximum offset for the image animation
        const imageOffset = Math.min(offset / 20, maxOffset); // Adjust the division factor for desired speed

        return `translateY(-${imageOffset}%)`;
    };

    const calculateAboutHomeOpacity = (offset) => {
        const maxOffset = 700; // Define the maximum offset for the fading effect
        var opacity = 1 - Math.min((offset - 200) / maxOffset, 1);
        if (isMobile) {
            opacity = 1 - Math.min((offset - 300) / maxOffset, 1);
        }
        return opacity;
    };

    const calculateCarouselOpacity = (offset) => {
        const maxOffset = 500; // Define the maximum offset for the fading effect
        const opacity = 0 + Math.min(offset / maxOffset, 1);
        return opacity;
    };


    const calculateTournamentOpacity = (offset) => {
        const maxOffset = 500; // Define the maximum offset for the fading effect
        const opacity = 0 + Math.min((offset - 600) / maxOffset, 1);
        return opacity;
    };

    const calculateMediaOpacity = (offset) => {
        const maxOffset = 500; // Define the maximum offset for the fading effect
        var opacity = 0 + Math.min((offset - 1200) / maxOffset, 1);
        if (isMobile) {
            opacity = 0 + Math.min((offset - 2300) / maxOffset, 1);
        }
        return opacity;
    };

    const calculateMediaOffset = (offset) => {
        const maxOffset = 60; // Define the maximum offset for the image animation
        const imageOffset = Math.min(offset / 30, maxOffset); // Adjust the division factor for desired speed

        return `translateX(${maxOffset - imageOffset}%)`;
    };
    const mobileStrategicPartner = () => {
        return (
            <div className='flex flex-wrap justify-content-center gap-1 card'>
                <h1 className='text-primary-900 font-bold'>{t("Strategic Partner")}</h1>
                {strategic && <img src={strategic.image} alt={strategic.name}></img>}
            </div>
        )
    }
    const strategicPartnerHome = () => {
        return (
            <div className='flex flex-wrap justify-content-center gap-2 card'>
                <h1 className='text-primary-900 font-bold text-xl'>{t("Strategic Partner")}</h1>
                {strategic && <img src={strategic.image} alt={strategic.name}></img>}
            </div>
        )
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', overflowX: 'hidden' }}>
            {isMobile ? (
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: "space-between", margin: '20px' }}>
                    <div
                        style={{
                            opacity: calculateAboutHomeOpacity(scrollPosition),
                            transition: 'opacity 0.3s ease',

                        }}
                        id="middle-component"
                    >
                        <AboutHome setActiveTab={setActiveTab} />
                    </div>
                    <div className='phone-image-container ' style={{ transform: calculateImageOffset(scrollPosition) }}>
                        {mobileStrategicPartner()}
                        <div className="image-space" />
                        <Image
                            src={img1}
                            alt="Image 1"
                            className="phone-image"
                            style={{ marginBottom: "20px", borderRadius: "5px", boxShadow: "0 0 10px var(--primary-900)", }}
                        />
                        <div className="image-space" />
                        <Image
                            src={img2}
                            alt="Image 2"
                            className="phone-image"
                            style={{ marginTop: "20px", borderRadius: "10px", boxShadow: "0 0 10px var(--primary-900)", }}
                        />
                    </div>
                </div>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '20px' }}>
                    <div className="animated-image left-img flex flex-wrap align-items-center justify-content-center gap-4" style={{
                        transform: calculateImageOffset(scrollPosition),
                    }}>
                        {strategicPartnerHome()}
                        <Image
                            src={img1}
                            alt="Image 1"
                            style={{
                                boxShadow: "0 0 10px var(--primary-900)", borderRadius: "10px"
                            }}
                            className=""
                        />
                    </div>
                    <div
                        style={{
                            opacity: calculateAboutHomeOpacity(scrollPosition),
                            transition: 'opacity 0.3s ease',
                        }}
                        id="middle-component"
                    >
                        <AboutHome setActiveTab={setActiveTab} />
                    </div>
                    <Image
                        src={img2}
                        alt="Image 2"
                        style={{ transform: calculateImageOffset(scrollPosition), borderRadius: "10px", boxShadow: "0 0 10px var(--primary-900)" }}
                        className="animated-image right-img"
                    />
                </div>
            )}

            <div className='other-home' style={{ width: '90%', opacity: calculateCarouselOpacity(scrollPosition), margin: '20px' }}>
                <SponsorsCarousel setActiveTab={setActiveTab} />
            </div>
            <div className='other-home'
                style={{
                    opacity: calculateTournamentOpacity(scrollPosition), transition: 'opacity 0.3s ease',
                    width: "95%", margin: "20px"
                }}>
                <HomeTournaments scrollPosition={scrollPosition} setActiveTab={setActiveTab} />
            </div>
            <div className='other-home'
                style={{
                    transform: calculateMediaOffset(scrollPosition),
                    opacity: calculateMediaOpacity(scrollPosition), transition: 'opacity 0.3s ease',
                    width: "95%", margin: "20px"
                }}>
                <HomeMedia scrollPosition={scrollPosition} setActiveTab={setActiveTab} />
            </div>
        </div>
    );
}

export default Home;
