import czechImg from './tournament_images/CzechOpen.jpg'
import pgaPolskaImg from './tournament_images/pgaPolska.jpg'
import proGolfQIg from './tournament_images/proQ.jpg'
import sbpImage from './sponsor_images/sbp.jpg';
import sobienieImage from './sponsor_images/sobienie.jpg';
import fiveStarsImage from './tournament_images/5stars.jpg';
import wierzchowiskaImage from './tournament_images/Wierzchowiska.png';
import sokhnaOpenImage from './tournament_images/sokhna_open.png';
import sokhnaClassicImage from './tournament_images/sokhna_classic.png';
import golfMadImage from './tournament_images/golf_mad.jpg'
import allgeriaImage from './tournament_images/allgeria.jpg'
import newGizaImage from './tournament_images/newgiza.jpg'
import haugschlagImage from './tournament_images/haugschlag.jpg'
import homburger from './tournament_images/homburger.png'
import cuberImage from './tournament_images/cuber.png'
import gradiImage from './tournament_images/gradi.jpg'
import lutytvp3 from './media_images/luty_tvp3.png'
import styczendw from './media_images/styczen_dw.png'
import loza from './media_images/loza.png'
import przeglad from './media_images/przeglad.png'
import pzg from './media_images/pzg.png'
import gnr from './media_images/gnr.jpg'
import kurier from './media_images/kurier.png'
import ping from './sponsor_images/ping.webp'
import marzectvp3 from './media_images/marzec_tvp3.png'
import kaskada from './tournament_images/kaskada.jpeg'
import tbv from './sponsor_images/tbv.png'
import sevilla from './tournament_images/Sevilla.png'
import profit from './sponsor_images/Profit_logo.png'
import pzpn from './media_images/kurier_pzpn.png'
import euram from './tournament_images/Euram.jpeg'
import vierumaki from './tournament_images/Vierumaki.jpeg'
import pgtq1 from './tournament_images/pgtg1.png';
import fullmed from './sponsor_images/fullmed.png'
import rosa from './sponsor_images/rosa.png'
import magazyn1109 from './media_images/magazyn_sportowy_11_09.png'
import popoludnie1109 from './media_images/popoludnie_11_09.png'
import placeholder from './my_images/rookie.jpg'
import v5 from './sponsor_images/v5.jpg'
import press from './sponsor_images/pressglass.jpg'
import React, { createContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const DataContext = createContext();
export const DataProvider = ({ children }) => {
    const { t } = useTranslation();

    const [pastTournamentsArr] = useState([
        {
            name: "Pro Golf Tour - Qualifying School Fleesensee 2024",
            date: "08-09/10/2024",
            scores: "77, 72",
            place: "T39",
            livescoring: "https://www.progolftour.de/tournament-details/qualifying-school-i-fleesensee-2024",
            details: "https://www.progolftour.de/press-detail/q-school-show-increases-excitement-for-2025",
            image: pgtq1
        },
        {
            name: "XV Turniej o Puchar Prezesa Spółdzielczego Banku Powiatowego w Piaskach",
            date: "12-13/09/2024",
            scores: "70, 73",
            place: "Winner",
            livescoring: "https://eagle2.pzgolf.pl/tournament/v320/#/11282/info",
            details: "https://eagle2.pzgolf.pl/tournament/v320/#/11282/info",
            image: sbpImage
        },
        {
            name: "Challenge Tour - Rosa Challenge Tour",
            date: "29/08-01/09/2024",
            scores: "70, 76",
            place: "CUT",
            livescoring: "https://www.europeantour.com/challenge-tour/rosa-challenge-tour-2024/",
            details: "https://www.europeantour.com/challenge-tour/rosa-challenge-tour-2024/",
            image: rosa
        },
        {
            name: "Challenge Tour - Vierumäki Finnish Challenge",
            date: "15-18/08/2024",
            scores: "71, 70",
            place: "CUT",
            livescoring: "https://www.europeantour.com/challenge-tour/vierumaki-finnish-challenge-2024/",
            details: "https://www.europeantour.com/challenge-tour/vierumaki-finnish-challenge-2024/",
            image: vierumaki
        },
        {
            name: "Challenge Tour - Euram Bank Open",
            date: "18-21/07/2024",
            scores: "74, 81",
            place: "CUT",
            livescoring: "https://www.europeantour.com/challenge-tour/euram-bank-open-2024/",
            details: "https://www.europeantour.com/challenge-tour/euram-bank-open-2024/",
            image: euram
        },
        {
            name: "Pro Golf Tour - Gradi Polish Open 2024",
            date: "03-05/07/2024",
            scores: "74, 71",
            place: "CUT",
            livescoring: "https://www.progolftour.de/tournament-details/gradi-polish-open-2024",
            details: "https://www.progolftour.de/press-detail/gugler-secures-third-win-of-the-season-in-poland",
            image: gradiImage
        },
        {
            name: "Pro Golf Tour - The Cuber Open 2024",
            date: "24-26/06/2024",
            scores: "75, 72",
            place: "CUT",
            livescoring: "https://www.progolftour.de/tournament-details/the-cuber-open-2024",
            details: "https://www.progolftour.de/press-detail/second-victory-of-the-season-for-meyer-de-beco",
            image: cuberImage
        },
        {
            name: "Challenge Tour - Kaskáda Golf Challenge",
            date: "13-16/06/2024",
            scores: "77, 74",
            place: "CUT",
            livescoring: "https://www.europeantour.com/challenge-tour/kaskada-golf-challenge-2024/leaderboard",
            details: "https://www.europeantour.com/challenge-tour/kaskada-golf-challenge-2024/",
            image: kaskada
        },
        {
            name: "Pro Golf Tour - Royal Homburger Open 2024",
            date: "03-05/06/2024",
            scores: "69, 67",
            place: "CUT",
            livescoring: "https://www.progolftour.de/tournament-details/royal-homburger-open-2024",
            details: "https://www.progolftour.de/press-detail/foley-finds-recipe-for-success-and-wins-in-bad-homburg",
            image: homburger
        },
        {
            name: "Challenge Tour - Challenge ge España",
            date: "9-12/05/2024",
            scores: "75, 76",
            place: "CUT",
            livescoring: "https://www.europeantour.com/challenge-tour/challenge-de-espana-2024/results?round=4",
            details: "https://www.europeantour.com/challenge-tour/challenge-de-espana-2024/",
            image: sevilla
        },
        {
            name: "Pro Golf Tour - Haugschlag NÖ Open by perfect eagle 2024",
            date: "24-26/04/2024",
            scores: "74, 71",
            place: "CUT",
            livescoring: "https://www.progolftour.de/tournament-details/haugschlag-nö-open-by-perfect-eagle-2024",
            details: "https://www.progolftour.de/press-detail/jean-bekirian-wins-thriller-at-haugschlag",
            image: haugschlagImage
        },
        {
            name: "Pro Golf Tour - NewGiza Pyramids Challenge 2024",
            date: "17-19/03/2024",
            scores: "78, 75",
            place: "CUT",
            livescoring: "https://www.progolftour.de/tournament-details/newgiza-pyramids-challenge-2024",
            details: "https://www.progolftour.de/press-detail/verdonk-proves-his-strength-at-the-newgiza-pyramids-challenge",
            image: newGizaImage
        },
        {
            name: "Pro Golf Tour - Allegria Open 2024",
            date: "12-14/03/2024",
            scores: "75, 68, 72",
            place: "T32",
            livescoring: "https://www.progolftour.de/tournament-details/allegria-open-2024",
            details: "https://www.progolftour.de/press-detail/gugler-presents-himself-with-a-premiere-victory-in-egypt",
            image: allgeriaImage
        },
        {
            name: "Pro Golf Tour - Golf Mad Championship 2024",
            date: "24-26/02/2024",
            scores: "81, 76",
            place: "CUT",
            livescoring: "https://www.progolftour.de/tournament-details/golf-mad-championship-2024",
            details: "https://www.progolftour.de/press-detail/first-tour-victory-play-off-win-for-meyer-de-beco",
            image: golfMadImage
        },
        {
            name: "Pro Golf Tour - Golf Mad Open 2024",
            date: "20-22/02/2024",
            scores: "79, 72",
            place: "CUT",
            livescoring: "https://www.progolftour.de/tournament-details/golf-mad-open-2024",
            details: "https://www.progolftour.de/tournament-details/golf-mad-open-2024",
            image: golfMadImage
        },
        {
            name: "Pro Golf Tour - Red Sea Egyptian Classic",
            date: "28-30/01/2024",
            scores: "79, 73",
            place: "CUT",
            livescoring: "https://www.progolftour.de/tournament-details/red-sea-egyptian-classic-2024",
            details: "https://www.progolftour.de/press-detail/first-victory-toorop-triumphs-in-the-wind-of-egypt",
            image: sokhnaClassicImage
        },
        {
            name: "Pro Golf Tour - Red Sea Ain Sokhna Open",
            date: "23-25/01/2024",
            livescoring: "https://www.progolftour.de/tournament-details/red-sea-ain-sokhna-open-2024",
            details: "https://www.progolftour.de/press-detail/clerici-celebrates-start-to-finish-victory-at-season-opener",
            scores: "71, 73, 74",
            place: "43",
            image: sokhnaOpenImage
        },
        {
            name: "5 Stars Junior Cup by Adrian Meronk",
            date: "13-15/10/2023",
            livescoring: "https://eagle2.pzgolf.pl/tournament/v315/#/9755/results",
            details: "https://5stars.pl/turniej/5-stars-junior-cup-2023-by-adrian-meronk/13-15-pazdziernika-2023-wroclaw-golf-club/",
            scores: "73, 71, 72",
            place: "2",
            image: fiveStarsImage
        },
        {
            name: "Pro Golf Tour -  Qualifying School I Verden 2023",
            date: "10-11/10/2023",
            livescoring: "https://www.progolftour.de/tournament-details/qschool-i-verden-2023",
            details: "https://www.progolftour.de/press-detail/cards-secured-smiling-faces-at-the-q-school",
            scores: "70, 72",
            place: "T7",
            image: proGolfQIg
        },
        {
            name: "Puchar Prezesa Banku Spółdzielczego Powiatowego w Piaskach",
            date: "23-24/09/2023",
            livescoring: "https://eagle2.pzgolf.pl/tournament/v315/#/9781/results",
            details: "https://golf.wierzchowiska.pl/turnieje.html",
            scores: "74, 69",
            place: "Winner",
            image: sbpImage
        },
        {
            name: "PGA Polska Championship",
            date: "18-19/09/2023",
            livescoring: "https://www.golfbox.dk/livescoring/tour/#/competition/3856001/leaderboard/3639967",
            details: "https://pgapolska.com/pl/aktualnosci/archive/polska/michal-bargenda-zwycieza-w-turnieju-pga-polska-championship",
            scores: "66, 69",
            place: "Winner",
            image: pgaPolskaImg
        },
        {
            name: "Otwarte Mistrzostwa Klubu Sobienie Królewskie G&CC",
            date: "02/09/2023",
            livescoring: "https://eagle2.pzgolf.pl/tournament/v313/#/9494/results",
            details: "http://golf.sobieniekrolewskie.pl/golf/galeria/mistrzostwa-klubu-sobienie-krolewskie-gcc-2023/",
            scores: "69",
            place: "Winner",
            image: sobienieImage
        },
        {
            name: "VIII Memoriał Wojciecha Świniarskiego",
            date: "26/08/2023",
            livescoring: "https://eagle2.pzgolf.pl/tournament/v315/#/9712/results",
            details: "https://golf.wierzchowiska.pl/turnieje.html",
            scores: "65",
            place: "Winner",
            image: wierzchowiskaImage
        },
        {
            name: "Czech Open",
            date: "17-20/08/2023",
            livescoring: "https://www.cgf.cz/cz/turnaje/turnaje-vyhledavani/turnaj/startovni-listina?id=733171494",
            details: "https://www.czechopenchampionship.cz",
            scores: "72, 78",
            place: "CUT",
            image: czechImg
        },
    ])

    const [upcomingTournamentsArr] = useState([

        {
            name: "TBD",
            date: "TBD",
            start: "2024-10-08T08:00:00",
            livescoring: "",
            details: "",
            image: placeholder
        }
    ])

    const media = [
        {
            name: "Magazyn Sportowy TVP 3 Lublin",
            date: "11/09/2024",
            link: "https://lublin.tvp.pl/82251349/11-wrzesnia-2024",
            image: magazyn1109
        },
        {
            name: "Miłego Popołudnia z TVP 3 Lublin (1:37:07)",
            date: "11/09/2024",
            link: "https://lublin.tvp.pl/82249115/11-sierpnia-2024",
            image: popoludnie1109
        },
        {
            name: "Kurier Lubelski",
            date: "10/06/2024",
            link: "https://kurierlubelski.pl/zawodowy-golfista-z-lublina-michal-bargenda-gral-z-lewandowskim-i-innymi-reprezentantami-polski/ar/c2-18595807",
            image: pzpn
        },
        {
            name: "Poranek z TVP 3 Lublin - Golf bez tajemnic",
            date: "07/03/2024",
            link: "https://lublin.tvp.pl/76319748/golf-bez-tajemnic?fbclid=IwAR3YuPe0vVl3GZ9ScFsm0PESnRnyWcNZGjvZ6bDRdJH9qJ4WWoc8sdPrJIA_aem_AUKNZDr6-j3ettx46N0Cp7dcJvvCkaP0j0nkUtpybSPav9hnW-ph2pbS9EfbXt3PLVl9kC4KNGlmKMmsdvAmfwsz",
            image: marzectvp3
        },
        {
            name: "Kurier Lubelski",
            date: "26/02/2024",
            link: "https://kurierlubelski.pl/rozmowa-z-michalem-bargenda-pierwszym-zawodowym-golfista-z-lublina-presja-coraz-wieksza/ar/c2-18338957?utm_source=facebook.com&utm_medium=1-liga&utm_content=sport&utm_campaign=rozmowa-z-michalem-bargenda-pierwszym-zawodowym-golfista-z-lublina-presja-coraz-wieksza&fbclid=IwAR1Lde08Bj9ayzamjwoarfNFu3SJpgEYGetrEhuWlf92aLj28oRJMFHwJ30",
            image: kurier
        },
        {
            name: "Poranek z TVP 3 Lublin",
            date: "02/02/2024",
            link: "https://drive.google.com/file/d/1oAtFDErEAPa2DBz90gsRkS6uIENy0spO/view?usp=share_link",
            image: lutytvp3
        },
        {
            name: "Dziennik Wschodni",
            date: "19/01/2024",
            link: "https://www.dziennikwschodni.pl/lublin/zawodowiec-z-lublina-rozpoczyna-przygode-z-pro-golf-tour,n,1000336732.html?fbclid=IwAR3lMaA4sHX9AbwPQVXPGgoebWNTDH-AeR2FLB-Sd8R-sXfwClhJMrS_fCU",
            image: styczendw
        },
        {
            name: "Golf&Roll - Loża szyderców",
            date: "12/2023",
            link: "https://drive.google.com/file/d/1kil1uqjYpG7bbQJTSeVBAXoGx4TjDBME/view?usp=share_link",
            image: loza
        },
        {
            name: "Onet - Przegląd Sportowy",
            date: "30/10/2023",
            link: "https://przegladsportowy.onet.pl/golf/przyjechala-z-usa-w-kadrze-zostali-para-celem-sa-igrzyska/9mhrvcz",
            image: przeglad
        },
        {
            name: "Polski Związek Golfa",
            date: "21/10/2023",
            link: "https://polski.golf/2023/10/bargenda-dolacza-do-grona-polskich-profesjonalistow/",
            image: pzg
        },
        {
            name: "Golf&Roll",
            date: "07/2023",
            link: "https://drive.google.com/file/d/1Ca9HPfLQe5vgoFuzKcQjzXugTEVeQxJe/view?usp=share_link",
            image: gnr
        },
    ]
    const [sponsorsArr] = useState([
        {
            image: sbpImage,
            name: "Spółdzielczy Bank Powiatowy w Piaskach",
            link: "https://www.sbppiaski.pl",
            type: t("Strategic Partner")
        },
        {
            image: ping,
            name: "PING Europe",
            link: "https://www.pingeurope.com/?gad_source=1&gclid=Cj0KCQjwqpSwBhClARIsADlZ_TlMeRP8ZdI_-u8mczKoqSQaI4-zBauUSIs9vuaXbJIwqRuI9lsh-gcaAksTEALw_wcB",
            type: "Partner"
        },
        {
            image: tbv,
            name: "TBV",
            link: "https://tbv.pl",
            type: "Partner"
        },
        {
            image: sobienieImage,
            name: "Sobienie Królewskie Golf & Country Club",
            link: "https://www.sobieniekrolewskie.pl",
            type: "Partner"
        },
        {
            image: fullmed,
            name: "Full-Med Jakub Sidorowicz",
            link: "https://full-med.pl",
            type: "Partner"
        },
        {
            image: profit,
            name: "Profit Sp. z o.o.",
            link: "",
            type: "Partner"
        },
        {
            image: rosa,
            name: "Rosa Golf Club",
            link: "https://rosa.golf",
            type: t("partner challenge")
        },
        {
            image: press,
            name: "Press Glass",
            link: "https://www.pressglass.com/",
            type: t("partner challenge")
        },
        {
            image: v5,
            name: "V5 Group",
            link: "https://www.v5group.pl/",
            type: t("partner challenge")
        }
    ])
    const [partnersArr] = useState([
        {
            image: ping,
            name: "PING Europe",
            link: "https://www.pingeurope.com/?gad_source=1&gclid=Cj0KCQjwqpSwBhClARIsADlZ_TlMeRP8ZdI_-u8mczKoqSQaI4-zBauUSIs9vuaXbJIwqRuI9lsh-gcaAksTEALw_wcB"
        },
        {
            image: tbv,
            name: "TBV",
            link: "https://tbv.pl"
        },
        {
            image: sobienieImage,
            name: "Sobienie Królewskie Golf & Country Club",
            link: "https://www.sobieniekrolewskie.pl"
        },
        {
            image: fullmed,
            name: "Full-Med Jakub Sidorowicz",
            link: "https://full-med.pl",
        },
        {
            image: profit,
            name: "Profit Sp. z o.o.",
            link: "",
            type: "Partner"
        },

    ])
    const [partnersChallengeArr] = useState([
        {
            image: rosa,
            name: "Rosa Golf Club",
            link: "https://rosa.golf"
        },
        {
            image: press,
            name: "Press Glass",
            link: "https://www.pressglass.com/"
        },
        {
            image: v5,
            name: "V5 Group",
            link: "https://www.v5group.pl/"
        }

    ])
    const [strategicPartner] = useState({
        image: sbpImage,
        name: "Spółdzielczy Bank Powiatowy w Piaskach",
        link: "https://www.sbppiaski.pl"
    })
    return (
        <DataContext.Provider value={{ pastTournamentsArr, upcomingTournamentsArr, sponsorsArr, media, partnersArr, strategicPartner, partnersChallengeArr }}>
            {children}
        </DataContext.Provider>
    );
}
export default DataProvider;