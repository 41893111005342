import React, { useEffect, useState } from 'react';
import './css/Partners.css'

function Footer({ activeTab }) {
    const [isScrorrable, setIsScrorrable] = useState(true);
    useEffect(() => {
        console.log(document.documentElement.scrollHeight > document.documentElement.clientHeight)
        setIsScrorrable(document.documentElement.scrollHeight > document.documentElement.clientHeight);
    }, [document, isScrorrable, activeTab]);
    return (

        <footer style={{
            backgroundColor: "var(--surface-0)", width: "100%", height: "35px", display: "flex", justifyContent: "center",
            alignItems: "center", position: isScrorrable ? "" : "fixed", bottom: 0
        }}>
            <div style={{ fontSize: "9px" }}>
                Designed and developed by Michal Bargenda
            </div>
        </footer >
    );
}

export default Footer;
