import { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataView } from 'primereact/dataview'
import { DataContext } from './DataContext';
import Countdown from './Countdown';
import './css/Schedule.css'

import { useTranslation } from 'react-i18next';
function Schedule({ setActiveTab }) {

    const { t } = useTranslation();
    const { pastTournamentsArr, upcomingTournamentsArr } = useContext(DataContext)
    const [past, setPast] = useState([]);
    const [future, setFuture] = useState([])
    useEffect(() => {
        setActiveTab("Schedule")
    }, [])
    useEffect(() => {
        setPast(pastTournamentsArr);
        setFuture(upcomingTournamentsArr);
        setActiveTab("")
        setActiveTab("Schedule")
    }, [pastTournamentsArr, upcomingTournamentsArr]);

    const tournamentPastTemplate = (tournament) => {
        return (
            <div className="tournament-card col-12">
                <div className="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4">
                    <img className="tournament-img w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round" src={tournament.image} alt={tournament.name} />
                    <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                        <div className="flex flex-column align-items-center sm:align-items-start gap-3">
                            <div className="text-2xl font-bold text-900">{tournament.name}</div>
                            <div className="flex align-items-center gap-3">
                                <span className="flex align-items-center gap-2">
                                    <i className="pi pi-calendar"></i>
                                    <span className="font-semibold">{tournament.date}</span>
                                </span>
                            </div>
                            <div className="flex align-items-center gap-3">
                                <span className="flex align-items-center gap-2">
                                    <i className="pi pi-align-left"></i>
                                    <span className="font-semibold">{tournament.place}</span>
                                </span>
                            </div>
                            <Button link icon="pi pi-link" label={t("results")} onClick={() => window.open(tournament.livescoring, '_blank')} />

                        </div>
                        <div className="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
                            <Button label={t("details")} icon="pi pi-info-circle" className="p-button-rounded" onClick={() => window.open(tournament.details, '_blank')} ></Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const tournamentUpcomingTemplate = (tournament) => {
        return (
            <div className="tournament-card col-12">
                <div className="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4">
                    <img className="tournament-img w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round" src={tournament.image} alt={tournament.name} />
                    <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                        <div className="flex flex-column align-items-center sm:align-items-start gap-3">
                            <div className="text-2xl font-bold text-900">{tournament.name}</div>
                            <div className="flex align-items-center gap-3">
                                <span className="flex align-items-center gap-2">
                                    <i className="pi pi-calendar"></i>
                                    <span className="font-semibold">{tournament.date}</span>
                                </span>
                            </div>
                            <Button link icon="pi pi-link" label={t("livescoring")} onClick={() => window.open(tournament.livescoring, '_blank')} />
                        </div>
                    </div>
                    <div className="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
                        <Button label={t("details")} icon="pi pi-info-circle" className="p-button-rounded" onClick={() => window.open(tournament.details, '_blank')} ></Button>
                    </div>
                </div>
                {tournament.date === upcomingTournamentsArr[0].date && <Countdown date={tournament.start} style={{ margin: "5px" }} />}
            </div>
        )
    }
    return (
        <div className="card flex justify-content-center mt-5 mb-5"  >
            <div className='menu-container' style={{ width: "80%" }}>
                <TabView>
                    <TabPanel header={t("upcoming tournaments")}>
                        <DataView value={future} itemTemplate={tournamentUpcomingTemplate} />
                    </TabPanel>
                    <TabPanel header={t("past tournaments")}>
                        <DataView value={past} itemTemplate={tournamentPastTemplate} />
                    </TabPanel>
                </TabView>
            </div >
        </div>
    );
}

export default Schedule;
